/*
author: Boostraptheme
author URL: https://boostraptheme.com
License: Creative Commons Attribution 4.0 Unported
License URL: https://creativecommons.org/licenses/by/4.0/
*/ 

/*====================================================
                   CONTACT-P1
======================================================*/
#contact-p1{
	padding: 100px 0 50px 0;
}

#contact-p1 .contact-p1-cont p{
	padding-right: 20px;
	font-size: 15px;
}

#contact-p1 .contact-p1-cont2 address,
#contact-p1 .contact-p1-cont2 address a{
	color:#666;
}

@media (min-width: 1600px) {
    #contact-p1 .contact-p1-cont p{
        padding-right: 20px;
        font-size: 16px;
    }
}

/*====================================================
                   CONTACT-P2
======================================================*/
 

    #contact-p2 .sub-but {
      margin: 0;
      text-align: center;
    }

    .con-form input {
        border-color: #ccc;
        border-radius: 0;
        color: #717171;
    }

    .con-form textarea {
        width: 100%; 
        height: 200px;
        margin:30px 0;
        border-color: #ccc;
        border-radius: 0;
        color: #717171;
    }


    .map-responsive{
    	margin-top: 70px;
        overflow:hidden;
        padding-bottom:50%;
        position:relative;
        height:0;
    }

    .map-responsive iframe{
        left:0;
        top:0;
        height:100%;
        width:100%;
        position:absolute;
    }

    @media(max-width: 767px){
        #contact-p2 .con-form input{
            margin-bottom: 30px;
        }
        .con-form textarea {
            width: 100%; 
            height: 200px;
            margin:10px 0 30px 0;
            border-color: #ccc;
            border-radius: 0;
            color: #717171;
        }
    }
/*
author: Boostraptheme
author URL: https://boostraptheme.com
License: Creative Commons Attribution 4.0 Unported
License URL: https://creativecommons.org/licenses/by/4.0/
*/ 

      /*=================================================
                      PRICE-5 
===================================================*/

    #price {
        background: #fff;
        padding: 100px 0;
    }

    .pricingTable {
        border: 1px solid #ddd;
        text-align: center;
        color: #333;
        padding: 50px 0 25px;
        margin-top: 30px;
        position: relative;
    }

    .pricingTable.active {
      margin-top: -10px;
      padding-top:90px;
      background: #f9f9f9;
      padding-bottom:65px;
    }

    .pricingTable.active ,
    .pricingTable:hover {
        border: 1px solid #2196f3;
    }

    .pricingTable .pricing-icon {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #ddd;
        position: absolute;
        top: -40px;
        left: 50%;
        padding: 10px;
        transform: translateX(-50%);
        transition: all 0.5s ease 0s;
    }

    .pricingTable.active .pricing-icon ,
    .pricingTable:hover .pricing-icon {
        border: 1px solid #2196f3;
        background: #2196f3;
    }

    .pricingTable .pricing-icon i {
        width: 100%;
        height: 100%;
        line-height: 58px;
        border-radius: 50%;
        background: #2196f3;
        font-size: 30px;
        color: #fff;
        transition: all 0.5s ease 0s;
    }

    .pricingTable.active .pricing-icon i ,
    .pricingTable:hover .pricing-icon i {
        color: #2196f3;
        background: #fff;
    }

    .pricingTable .price-Value {
        display: inline-block;
        font-size: 60px;
        color: #494949;
        position: relative;
    }

    .pricingTable .currency {
        font-size: 25px;
        position: absolute;
        top: 18px;
        left: -18px;
    }

    .pricingTable .month {
        display: block;
        font-size: 17px;
        text-transform: capitalize;
    }

    .pricingTable .pricingHeader {
        position: relative;
        z-index: 1;
    }

    .pricingTable .pricingHeader:after {
        content: "";
        width: 100%;
        height: 1px;
        background: #ddd;
        position: absolute;
        top: 50%;
        left: 0;
        z-index: -1;
    }

    .pricingTable .title {
        width: 50%;
        background: #2196f3;
        margin: 10px auto;
        padding: 10px 0;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        position: relative;
        z-index: 1;
    }

    .pricingTable .month ,
    .pricingTable .pricing-content ul {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        color: #555;
        line-height: 32px;
        margin-bottom: 25px;
    } 

    .pricingTable .pricingTable-signup {
        display: inline-block;
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        background: #2196f3;
        border: 1px solid #2196f3;
        padding: 5px 20px;
        transition: all 0.5s ease 0s;
    }

    .pricingTable.active .pricingTable-signup ,
    .pricingTable .pricingTable-signup:hover {
        background: #fff;
        color: #2196f3;
    }

    @media only screen and (max-width:990px) {
        .pricingTable {
            margin-bottom: 20px;
        }
    }

    @media only screen and (max-width:767px) {
        .pricingTable {
            margin-bottom: 60px;
        }
    }
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

#root:not(:empty) + .sk-cube-grid { 
  display: none; 
} 
.sk-cube-grid { 
  width: 40px; 
  height: 40px; 
  margin: 100px auto; 
} 
.sk-cube-grid .sk-cube { 
  width: 33%; 
  height: 33%; 
  background-color: #333; 
  float: left; 
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
} 
.sk-cube-grid .sk-cube1 { 
  animation-delay: 0.2s; 
} 
.sk-cube-grid .sk-cube2 { 
  animation-delay: 0.3s; 
} 
.sk-cube-grid .sk-cube3 { 
  animation-delay: 0.4s; 
} 
.sk-cube-grid .sk-cube4 { 
  animation-delay: 0.1s; 
} 
.sk-cube-grid .sk-cube5 { 
  animation-delay: 0.2s; 
} 
.sk-cube-grid .sk-cube6 { 
  animation-delay: 0.3s; 
} 
.sk-cube-grid .sk-cube7 { 
  animation-delay: 0s; 
} 
.sk-cube-grid .sk-cube8 { 
  animation-delay: 0.1s; 
} 
.sk-cube-grid .sk-cube9 { 
  animation-delay: 0.2s; 
} 
@keyframes sk-cubeGridScaleDelay { 
  0%, 70%, 100% { 
      transform: scale3D(1, 1, 1); 
  } 
  35% { 
      transform: scale3D(0, 0, 1); 
  } 
}
